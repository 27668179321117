import { paramCase } from 'src/utils/change-case';

import { _id, _postTitles } from 'src/_mock/assets';

// ----------------------------------------------------------------------

export type ROOTS_TYPE = keyof typeof ROOTS;

export type DashboardPaths = {
  statistics: string;
  projects: {
    root: string;
    all_project: string;
    add_project: string;
    project_requests: string;
    // edit: string;
    // edit_request: string;
    detail_project: (id: string) => string;
    // campaign_list_project: string;
    // item_list_project: string;
    // transaction_donors_list: string;
  };
  campaigns: {
    root: string;
    all_campaign: string;
    my_campaigns: string;
    add_campaign: string;
    favorites_campaign: string;
    vendor_requests: string;
    send_back_requests: string;
    operator: {
      pay_to_vendor: (campaign_id: string, vendor_id: string) => string;
    };
    vendor: {
      milestones: {
        root: (campaign_id: string) => string;
        detail_milestone: (campaign_id: string, milestone_id: string) => string;
        all_progress: (campaign_id: string, milestone_id: string) => string;
      };
      progresses: {
        root: (campaign_id: string) => string;
      };
      invoices: {
        root: (campaign_id: string) => string;
        campaign_invoices: (campaign_id: string, vendor_id: string) => string;
      };
      payment_history_progress: {
        root: (campaign_id: string) => string;
      };
      send_back_requests: {
        root: (campaign_id: string) => string;
        request_form: (campaign_id: string) => string;
        detail_request_send_back: (campaign_id: string, request_id: string) => string;
      };
    };
    edit_campaign: (id: string) => string;
    edit_request_campaign: (id: string) => string;
    detail_campaign: (id: string) => string;
    detail_vendor_request: (id: string) => string;
    campaign_vendors_list: (id: string) => string;
    transaction_donors_list: (id: string) => string;
    transaction_list_campaign: (id: string) => string;
    // vendors_campaign_milstones: (cid: string) => string;
    detail_vendor: (campaign_id: string, vendor_id: string) => string;
    detail_vendor_progresses: (campaign_id: string, vendor_id: string) => string;
  };
  vendors: {
    root: string;
    all_vendor: string;
    requests_vendor: string;
    add_vendor: string;
    edit_vendor: (id: string) => string;
    detail_vendor: (id: string) => string;
    detail_requests_vendor: (id: string) => string;
  };
  donors: {
    root: string;
    all_donors: string;
    detail_donor: (id: string) => string;
  };
  tickets: {
    root: string;
    all_tickets: string;
    add_ticket: string;
    detail_ticket: (id: string) => string;
  };
  settings: {
    root: string;
    account_settings: string;
    security_settings: string;
    my_cards: string;
    public_profile: string;
  };
  operators: {
    root: string;
    all_operator: string;
    add_operator: string;
    edit_operator: (id: string) => string;
    detail_operator: (id: string) => string;
  };
  evaluators: {
    root: string;
    all_evaluator: string;
    add_evaluator: string;
    edit_evaluator: (id: string) => string;
    detail_evaluator: (id: string) => string;
    evaluator_maintenance_requests: string;
    evaluator_maintenance_request_details: (id: string) => string;
    add_mosque_to_maintenance: string;
    all_mosques_evaluated: string;
    detail_mosques_evaluated: (evaluation_task_id: string) => string;
  };
  maintenance_request: {
    root: string;
    all_maintenance_request: string;
    detail_maintenance_request: (maintenance_request_id: string) => string;
    my_evaluation_task: string;
    detail_evaluation_task: (maintenance_request_id: string) => string;
    project_evaluated: string;
    detail_project_evaluated: (maintenance_request_id: string) => string;
  };
};

// ----------------------------------------------------------------------

const MOCK_ID = _id[1];

const MOCK_TITLE = _postTitles[2];

export const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/dashboard',
  ADMIN: '/admin',
  OPERATOR: '/operator',
  EVALUATOR: '/evaluator',
  VENDOR: '/vendor',
};

// ----------------------------------------------------------------------

export const PATHS_ADMIN = {
  statistics: `${ROOTS.ADMIN}/dashboard/statistics`,
  projects: {
    root: `${ROOTS.ADMIN}/project`,
    all_project: `${ROOTS.ADMIN}/project/all`,
    add_project: `${ROOTS.ADMIN}/project/add`,
    project_requests: `${ROOTS.ADMIN}/project/requests`,
    edit: (id: string) => `${ROOTS.ADMIN}/project/edit/${id}`,
    edit_request: (id: string) => `${ROOTS.ADMIN}/project/edit-request/${id}`,
    detail_project: (id: string) => `${ROOTS.ADMIN}/project/detail/${id}`,
    campaign_list_project: (id: string) => `${ROOTS.ADMIN}/project/detail/${id}/campaigns`,
    item_list_project: (id: string) => `${ROOTS.ADMIN}/project/detail/${id}/items`,
    transaction_donors_list: (id: string) => `${ROOTS.ADMIN}/project/detail/${id}/donors`,
  },
  campaigns: {
    root: `${ROOTS.ADMIN}/campaign`,
    all_campaign: `${ROOTS.ADMIN}/campaign/all`,
    add_campaign: `${ROOTS.ADMIN}/campaign/add`,
    edit_campaign: (id: string) => `${ROOTS.ADMIN}/campaign/edit/${id}`,
    detail_campaign: (id: string) => `${ROOTS.ADMIN}/campaign/detail/${id}`,
    edit_request_campaign: (id: string) => `${ROOTS.ADMIN}/campaign/edit-request/${id}`,
    transaction_list_campaign: (id: string) =>
      `${ROOTS.ADMIN}/campaign/detail/${id}/payment-history`,
    transaction_donors_list: (id: string) => `${ROOTS.ADMIN}/campaign/detail/${id}/donors`,
    campaign_vendors_list: (id: string) => `${ROOTS.ADMIN}/campaign/detail/${id}/vendors`,
  },
  operators: {
    root: `${ROOTS.ADMIN}/operator`,
    all_operator: `${ROOTS.ADMIN}/operator/all`,
  },
  evaluators: {
    root: `${ROOTS.ADMIN}/evaluator`,
    all_evaluator: `${ROOTS.ADMIN}/evaluator/all`,
  },
  vendors: {
    root: `${ROOTS.ADMIN}/vendor`,
    all_vendor: `${ROOTS.ADMIN}/vendor/all`,
  },
  marketplace: {
    root: `${ROOTS.ADMIN}/marketplace`,
    all_item: `${ROOTS.ADMIN}/marketplace/all`,
    add_item: `${ROOTS.ADMIN}/marketplace/add`,
    detail_item: (id: string) => `${ROOTS.ADMIN}/marketplace/detail/${id}`,
    edit_item: (id: string) => `${ROOTS.ADMIN}/marketplace/edit/${id}`,
    edit_request_item: (id: string) => `${ROOTS.ADMIN}/marketplace/edit-request/${id}`,
    transaction_donors_list: (id: string) => `${ROOTS.ADMIN}/marketplace/detail/${id}/donors`,
  },
  donors: `${ROOTS.ADMIN}/donor`,
  evaluator: `${ROOTS.ADMIN}/evaluator`,
  tickets: `${ROOTS.ADMIN}/tickets`,
  directories: `${ROOTS.ADMIN}/directories`,
  maintenance_request: `${ROOTS.ADMIN}/maintenance-request`,
  community: `${ROOTS.ADMIN}/community`,
  blogs: `${ROOTS.ADMIN}/blog`,
};

export const PATHS_OPERATOR = {
  statistics: `${ROOTS.OPERATOR}/dashboard/statistics`,
  projects: {
    root: `${ROOTS.OPERATOR}/project`,
    all_project: `${ROOTS.OPERATOR}/project/all`,
    add_project: `${ROOTS.OPERATOR}/project/add`,
    edit: (id: string) => `${ROOTS.OPERATOR}/project/edit/${id}`,
    edit_request: (id: string) => `${ROOTS.OPERATOR}/project/edit-request/${id}`,
    detail_project: (id: string) => `${ROOTS.OPERATOR}/project/detail/${id}`,
    campaign_list_project: (id: string) => `${ROOTS.OPERATOR}/project/detail/${id}/campaigns`,
    item_list_project: (id: string) => `${ROOTS.OPERATOR}/project/detail/${id}/items`,
    transaction_donors_list: (id: string) => `${ROOTS.OPERATOR}/project/detail/${id}/donors`,
  },
  campaigns: {
    root: `${ROOTS.OPERATOR}/campaign`,
    all_campaign: `${ROOTS.OPERATOR}/campaign/all`,
    add_campaign: `${ROOTS.OPERATOR}/campaign/add`,
    edit_campaign: (id: string) => `${ROOTS.OPERATOR}/campaign/edit/${id}`,
    detail_campaign: (id: string) => `${ROOTS.OPERATOR}/campaign/detail/${id}`,
    edit_request_campaign: (id: string) => `${ROOTS.OPERATOR}/campaign/edit-request/${id}`,
    transaction_list_campaign: (id: string) =>
      `${ROOTS.OPERATOR}/campaign/detail/${id}/payment-history`,
    transaction_donors_list: (id: string) => `${ROOTS.OPERATOR}/campaign/detail/${id}/donors`,
    campaign_vendors_list: (id: string) => `${ROOTS.OPERATOR}/campaign/detail/${id}/vendors`,
  },
  operators: {
    root: `${ROOTS.OPERATOR}/operator`,
    all_operator: `${ROOTS.OPERATOR}/operator/all`,
  },
  evaluators: {
    root: `${ROOTS.ADMIN}/evaluator`,
    all_evaluator: `${ROOTS.ADMIN}/evaluator/all`,
  },
  vendors: {
    root: `${ROOTS.OPERATOR}/vendor`,
    all_vendor: `${ROOTS.OPERATOR}/vendor/all`,
  },
  marketplace: {
    root: `${ROOTS.OPERATOR}/marketplace`,
    all_item: `${ROOTS.OPERATOR}/marketplace/all`,
    add_item: `${ROOTS.OPERATOR}/marketplace/add`,
    detail_item: (id: string) => `${ROOTS.OPERATOR}/marketplace/detail/${id}`,
    edit_item: (id: string) => `${ROOTS.OPERATOR}/marketplace/edit/${id}`,
    edit_request_item: (id: string) => `${ROOTS.OPERATOR}/marketplace/edit-request/${id}`,
    transaction_donors_list: (id: string) => `${ROOTS.OPERATOR}/marketplace/detail/${id}/donors`,
  },
};

export const PATHS_EVALUATOR = {
  statistics: `${ROOTS.EVALUATOR}/dashboard/statistics`,
  projects: {
    root: `${ROOTS.EVALUATOR}/project`,
    all_project: `${ROOTS.EVALUATOR}/project/all`,
    add_project: `${ROOTS.EVALUATOR}/project/add`,
    edit: (id: string) => `${ROOTS.EVALUATOR}/project/edit/${id}`,
    edit_request: (id: string) => `${ROOTS.EVALUATOR}/project/edit-request/${id}`,
    detail_project: (id: string) => `${ROOTS.EVALUATOR}/project/${id}`,
    campaign_list_project: (id: string) => `${ROOTS.EVALUATOR}/project/${id}/campaigns`,
    item_list_project: (id: string) => `${ROOTS.EVALUATOR}/project/${id}/items`,
    transaction_donors_list: (id: string) => `${ROOTS.EVALUATOR}/project/${id}/donors`,
  },
  campaigns: {
    root: `${ROOTS.EVALUATOR}/campaign`,
    all_campaign: `${ROOTS.EVALUATOR}/campaign/all`,
    add_campaign: `${ROOTS.EVALUATOR}/campaign/add`,
    edit_campaign: (id: string) => `${ROOTS.EVALUATOR}/campaign/edit/${id}`,
    detail_campaign: (id: string) => `${ROOTS.EVALUATOR}/campaign/detail/${id}`,
    edit_request_campaign: (id: string) => `${ROOTS.EVALUATOR}/campaign/edit-request/${id}`,
    transaction_list_campaign: (id: string) =>
      `${ROOTS.EVALUATOR}/campaign/detail/${id}/payment-history`,
    transaction_donors_list: (id: string) => `${ROOTS.EVALUATOR}/campaign/detail/${id}/donors`,
    campaign_vendors_list: (id: string) => `${ROOTS.EVALUATOR}/campaign/detail/${id}/vendors`,
  },
  operators: {
    root: `${ROOTS.EVALUATOR}/operator`,
    all_operator: `${ROOTS.EVALUATOR}/operator/all`,
  },
  evaluators: {
    root: `${ROOTS.EVALUATOR}/evaluator`,
    all_evaluator: `${ROOTS.EVALUATOR}/evaluator/all`,
  },
  vendors: {
    root: `${ROOTS.EVALUATOR}/vendor`,
    all_vendor: `${ROOTS.EVALUATOR}/vendor/all`,
  },
  marketplace: {
    root: `${ROOTS.EVALUATOR}/marketplace`,
    all_item: `${ROOTS.EVALUATOR}/marketplace/all`,
    add_item: `${ROOTS.EVALUATOR}/marketplace/add`,
    detail_item: (id: string) => `${ROOTS.EVALUATOR}/marketplace/detail/${id}`,
    edit_item: (id: string) => `${ROOTS.EVALUATOR}/marketplace/edit/${id}`,
    edit_request_item: (id: string) => `${ROOTS.EVALUATOR}/marketplace/edit-request/${id}`,
    transaction_donors_list: (id: string) => `${ROOTS.EVALUATOR}/marketplace/detail/${id}/donors`,
  },
};

export const PATHS_VENDOR = {
  statistics: `${ROOTS.VENDOR}/dashboard/statistics`,
  projects: {
    root: `${ROOTS.VENDOR}/project`,
    all_project: `${ROOTS.VENDOR}/project/all`,
    add_project: `${ROOTS.VENDOR}/project/add`,
    edit: (id: string) => `${ROOTS.VENDOR}/project/edit/${id}`,
    edit_request: (id: string) => `${ROOTS.VENDOR}/project/edit-request/${id}`,
    detail_project: (id: string) => `${ROOTS.VENDOR}/project/${id}`,
    campaign_list_project: (id: string) => `${ROOTS.VENDOR}/project/${id}/campaigns`,
    item_list_project: (id: string) => `${ROOTS.VENDOR}/project/${id}/items`,
    transaction_donors_list: (id: string) => `${ROOTS.VENDOR}/project/${id}/donors`,
  },
  campaigns: {
    root: `${ROOTS.VENDOR}/campaign`,
    all_campaign: `${ROOTS.VENDOR}/campaign/all`,
    add_campaign: `${ROOTS.VENDOR}/campaign/add`,
    edit_campaign: (id: string) => `${ROOTS.VENDOR}/campaign/edit/${id}`,
    detail_campaign: (id: string) => `${ROOTS.VENDOR}/campaign/detail/${id}`,
    edit_request_campaign: (id: string) => `${ROOTS.VENDOR}/campaign/edit-request/${id}`,
    transaction_list_campaign: (id: string) =>
      `${ROOTS.VENDOR}/campaign/detail/${id}/payment-history`,
    transaction_donors_list: (id: string) => `${ROOTS.VENDOR}/campaign/detail/${id}/donors`,
    campaign_vendors_list: (id: string) => `${ROOTS.VENDOR}/campaign/detail/${id}/vendors`,
  },
  operators: {
    root: `${ROOTS.VENDOR}/operator`,
    all_operator: `${ROOTS.VENDOR}/operator/all`,
  },
  evaluators: {
    root: `${ROOTS.ADMIN}/evaluator`,
    all_evaluator: `${ROOTS.ADMIN}/evaluator/all`,
  },
  vendors: {
    root: `${ROOTS.VENDOR}/vendor`,
    all_vendor: `${ROOTS.VENDOR}/vendor/all`,
  },
  marketplace: {
    root: `${ROOTS.VENDOR}/marketplace`,
    all_item: `${ROOTS.VENDOR}/marketplace/all`,
    add_item: `${ROOTS.VENDOR}/marketplace/add`,
    detail_item: (id: string) => `${ROOTS.VENDOR}/marketplace/detail/${id}`,
    edit_item: (id: string) => `${ROOTS.VENDOR}/marketplace/edit/${id}`,
    edit_request_item: (id: string) => `${ROOTS.VENDOR}/marketplace/edit-request/${id}`,
    transaction_donors_list: (id: string) => `${ROOTS.VENDOR}/marketplace/detail/${id}/donors`,
  },
};

export const PATHS_DASHBOARD = (role: ROOTS_TYPE): DashboardPaths => ({
  statistics: `${ROOTS[role]}/dashboard/statistics`,
  projects: {
    root: `${ROOTS[role]}/project`,
    all_project: `${ROOTS[role]}/project/all`,
    add_project: `${ROOTS[role]}/project/add`,
    project_requests: `${ROOTS[role]}/project/requests`,
    detail_project: (id) => `${ROOTS[role]}/project/detail/${id}`,
  },
  campaigns: {
    root: `${ROOTS[role]}/campaign`,
    all_campaign: `${ROOTS[role]}/campaign/all`,
    my_campaigns: `${ROOTS[role]}/campaign/my-campaigns`,
    add_campaign: `${ROOTS[role]}/campaign/add`,
    favorites_campaign: `${ROOTS[role]}/campaign/favorites`,
    vendor_requests: `${ROOTS[role]}/campaign/vendor-requests`,
    send_back_requests: `${ROOTS[role]}/campaign/send-back-requests`,
    edit_campaign: (id: string) => `${ROOTS[role]}/campaign/edit/${id}`,
    edit_request_campaign: (id: string) => `${ROOTS[role]}/campaign/edit-request/${id}`,
    detail_campaign: (id) => `${ROOTS[role]}/campaign/detail/${id}`,
    detail_vendor: (campaign_id, vendor_id) =>
      `${ROOTS[role]}/campaign/detail/${campaign_id}/vendors/${vendor_id}`,
    detail_vendor_progresses: (campaign_id, vendor_id) =>
      `${ROOTS[role]}/campaign/detail/${campaign_id}/vendors/${vendor_id}/progresses`,
    detail_vendor_request: (id) => `${ROOTS[role]}/campaign/vendor-requests/${id}`,
    campaign_vendors_list: (id: string) => `${ROOTS[role]}/campaign/detail/${id}/vendors`,
    transaction_donors_list: (id: string) => `${ROOTS[role]}/campaign/detail/${id}/donors`,
    transaction_list_campaign: (id: string) =>
      `${ROOTS[role]}/campaign/detail/${id}/payment-history`,
    vendor: {
      milestones: {
        root: (campaign_id) => `${ROOTS[role]}/campaign/detail/${campaign_id}/milestones`,
        detail_milestone: (campaign_id, milestone_id) =>
          `${ROOTS[role]}/campaign/detail/${campaign_id}/milestones/${milestone_id}`,
        all_progress: (campaign_id, milestone_id) =>
          `${ROOTS[role]}/campaign/detail/${campaign_id}/milestones/${milestone_id}/all-progress`,
      },
      progresses: {
        root: (campaign_id) => `${ROOTS[role]}/campaign/detail/${campaign_id}/progresses`,
      },
      invoices: {
        root: (campaign_id) => `${ROOTS[role]}/campaign/detail/${campaign_id}/invoices`,
        campaign_invoices: (campaign_id, vendor_id) =>
          `${ROOTS[role]}/campaign/detail/${campaign_id}/vendors/${vendor_id}/invoices`,
      },
      payment_history_progress: {
        root: (campaign_id) =>
          `${ROOTS[role]}/campaign/detail/${campaign_id}/payment-history-progress`,
      },
      send_back_requests: {
        root: (campaign_id) => `${ROOTS[role]}/campaign/detail/${campaign_id}/send-back-requests`,
        request_form: (campaign_id) =>
          `${ROOTS[role]}/campaign/detail/${campaign_id}/send-back-requests/form-request`,
        detail_request_send_back: (campaign_id, request_id) =>
          `${ROOTS[role]}/campaign/detail/${campaign_id}/send-back-requests/${request_id}`,
      },
    },
    operator: {
      pay_to_vendor: (campaign_id, vendor_id) =>
        `${ROOTS[role]}/campaign/detail/${campaign_id}/vendors/${vendor_id}/pay-to-vendor`,
    },
  },
  operators: {
    root: `${ROOTS.ADMIN}/operator`,
    all_operator: `${ROOTS.ADMIN}/operator/all`,
    add_operator: `${ROOTS.ADMIN}/operator/add`,
    edit_operator: (id) => `${ROOTS[role]}/operator/update/${id}`,
    detail_operator: (id) => `${ROOTS[role]}/operator/detail/${id}`,
  },
  evaluators: {
    root: `${ROOTS.ADMIN}/evaluator`,
    all_evaluator: `${ROOTS.ADMIN}/evaluator/all`,
    add_evaluator: `${ROOTS.ADMIN}/evaluator/add`,
    edit_evaluator: (id) => `${ROOTS[role]}/evaluator/update/${id}`,
    detail_evaluator: (id) => `${ROOTS[role]}/evaluator/detail/${id}`,
    evaluator_maintenance_requests: `${ROOTS[role]}/evaluator/maintenance-requests`,
    evaluator_maintenance_request_details: (id) =>
      `${ROOTS[role]}/evaluator/maintenance-requests/${id}`,
    add_mosque_to_maintenance: `${ROOTS.ADMIN}/evaluator/add-mosque-to-maintenance`,
    all_mosques_evaluated: `${ROOTS[role]}/evaluator/mosques-evaluated`,
    detail_mosques_evaluated: (evaluation_task_id: string) =>
      `${ROOTS[role]}/evaluator/mosques-evaluated/detail/${evaluation_task_id}`,
  },
  vendors: {
    root: `${ROOTS[role]}/vendor`,
    all_vendor: `${ROOTS[role]}/vendor/all`,
    requests_vendor: `${ROOTS[role]}/vendor/requests`,
    add_vendor: `${ROOTS[role]}/vendor/add`,
    detail_vendor: (id) => `${ROOTS[role]}/vendor/detail/${id}`,
    edit_vendor: (id) => `${ROOTS[role]}/vendor/detail/${id}/edit`,
    detail_requests_vendor: (id) => `${ROOTS[role]}/vendor/requests/detail/${id}`,
  },
  donors: {
    root: `${ROOTS[role]}/donor`,
    all_donors: `${ROOTS[role]}/donor/all`,
    detail_donor: (id: string) => `${ROOTS[role]}/donor/detail/${id}`,
  },
  tickets: {
    root: `${ROOTS[role]}/tickets`,
    all_tickets: `${ROOTS[role]}/tickets/all`,
    add_ticket: `${ROOTS[role]}/tickets/add`,
    detail_ticket: (id: string) => `${ROOTS[role]}/tickets/detail/${id}`,
  },
  settings: {
    root: `${ROOTS[role]}/settings`,
    account_settings: `${ROOTS[role]}/settings/account-info`,
    security_settings: `${ROOTS[role]}/settings/security`,
    my_cards: `${ROOTS[role]}/settings/my-cards`,
    public_profile: `${ROOTS[role]}/settings/public-profile`,
  },
  maintenance_request: {
    root: `${ROOTS[role]}/maintenance-request`,
    all_maintenance_request: `${ROOTS[role]}/maintenance-request/all`,
    detail_maintenance_request: (maintenance_request_id: string) =>
      `${ROOTS[role]}/maintenance-request/detail/${maintenance_request_id}`,
    my_evaluation_task: `${ROOTS[role]}/maintenance-request/my-evaluation-task`,
    detail_evaluation_task: (maintenance_request_id: string) =>
      `${ROOTS[role]}/maintenance-request/my-evaluation-task/detail/${maintenance_request_id}`,
    project_evaluated: `${ROOTS[role]}/maintenance-request/project-evaluated`,
    detail_project_evaluated: (maintenance_request_id: string) =>
      `${ROOTS[role]}/maintenance-request/project-evaluated/detail/${maintenance_request_id}`,
  },
});

export const paths = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  docs: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
  zoneUI: 'https://mui.com/store/items/zone-landing-page/',
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  freeUI: 'https://mui.com/store/items/minimal-dashboard-free/',
  figma:
    'https://www.figma.com/file/hjxMnGUJCjY7pX8lQbS7kn/%5BPreview%5D-Minimal-Web.v5.4.0?type=design&node-id=0-1&mode=design&t=2fxnS70DuiTLGzND-0',
  product: {
    root: `/product`,
    checkout: `/product/checkout`,
    details: (id: string) => `/product/${id}`,
    demo: {
      details: `/product/${MOCK_ID}`,
    },
  },
  post: {
    root: `/post`,
    details: (title: string) => `/post/${paramCase(title)}`,
    demo: {
      details: `/post/${paramCase(MOCK_TITLE)}`,
    },
  },
  // AUTH
  auth: {
    amplify: {
      login: `${ROOTS.AUTH}/amplify/login`,
      verify: `${ROOTS.AUTH}/amplify/verify`,
      register: `${ROOTS.AUTH}/amplify/register`,
      newPassword: `${ROOTS.AUTH}/amplify/new-password`,
      forgotPassword: `${ROOTS.AUTH}/amplify/forgot-password`,
    },
    user: {
      check: `${ROOTS.AUTH}/user/verify`,
    },
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
    firebase: {
      login: `${ROOTS.AUTH}/firebase/login`,
      verify: `${ROOTS.AUTH}/firebase/verify`,
      register: `${ROOTS.AUTH}/firebase/register`,
      forgotPassword: `${ROOTS.AUTH}/firebase/forgot-password`,
    },
    auth0: {
      login: `${ROOTS.AUTH}/auth0/login`,
    },
  },
  authDemo: {
    classic: {
      login: `${ROOTS.AUTH_DEMO}/classic/login`,
      register: `${ROOTS.AUTH_DEMO}/classic/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/classic/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/classic/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/classic/verify`,
    },
    modern: {
      login: `${ROOTS.AUTH_DEMO}/modern/login`,
      register: `${ROOTS.AUTH_DEMO}/modern/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/modern/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/modern/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/modern/verify`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    mail: `${ROOTS.DASHBOARD}/mail`,
    chat: `${ROOTS.DASHBOARD}/chat`,
    blank: `${ROOTS.DASHBOARD}/blank`,
    kanban: `${ROOTS.DASHBOARD}/kanban`,
    calendar: `${ROOTS.DASHBOARD}/calendar`,
    fileManager: `${ROOTS.DASHBOARD}/file-manager`,
    permission: `${ROOTS.DASHBOARD}/permission`,
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
      ecommerce: `${ROOTS.DASHBOARD}/ecommerce`,
      analytics: `${ROOTS.DASHBOARD}/analytics`,
      banking: `${ROOTS.DASHBOARD}/banking`,
      booking: `${ROOTS.DASHBOARD}/booking`,
      file: `${ROOTS.DASHBOARD}/file`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      new: `${ROOTS.DASHBOARD}/user/new`,
      list: `${ROOTS.DASHBOARD}/user/list`,
      cards: `${ROOTS.DASHBOARD}/user/cards`,
      profile: `${ROOTS.DASHBOARD}/user/profile`,
      account: `${ROOTS.DASHBOARD}/user/account`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
      demo: {
        edit: `${ROOTS.DASHBOARD}/user/${MOCK_ID}/edit`,
      },
    },
    product: {
      root: `${ROOTS.DASHBOARD}/product`,
      new: `${ROOTS.DASHBOARD}/product/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/product/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/product/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/product/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/product/${MOCK_ID}/edit`,
      },
    },
    invoice: {
      root: `${ROOTS.DASHBOARD}/invoice`,
      new: `${ROOTS.DASHBOARD}/invoice/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/invoice/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/invoice/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}/edit`,
      },
    },
    post: {
      root: `${ROOTS.DASHBOARD}/post`,
      new: `${ROOTS.DASHBOARD}/post/new`,
      details: (title: string) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}`,
      edit: (title: string) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}`,
        edit: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}/edit`,
      },
    },
    order: {
      root: `${ROOTS.DASHBOARD}/order`,
      details: (id: string) => `${ROOTS.DASHBOARD}/order/${id}`,
      demo: {
        details: `${ROOTS.DASHBOARD}/order/${MOCK_ID}`,
      },
    },
    job: {
      root: `${ROOTS.DASHBOARD}/job`,
      new: `${ROOTS.DASHBOARD}/job/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/job/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/job/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/job/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/job/${MOCK_ID}/edit`,
      },
    },
    tour: {
      root: `${ROOTS.DASHBOARD}/tour`,
      new: `${ROOTS.DASHBOARD}/tour/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/tour/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/tour/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}/edit`,
      },
    },
  },
};
